import { createTheme } from "@mui/material";

import { designTokens } from "./theme.tokens";
import { createShadows } from "./theme.helper";

export { type Theme, useTheme } from "@mui/material/styles";
export { makeStyles, styled } from "@mui/styles";

export const colorThemeRapidResponse = createTheme({
	palette: {
		primary: {
			main: designTokens.colors.purple.main,
			contrastText: "#fff",
		},
		secondary: {
			light: designTokens.colors.green.mid,
			main: designTokens.colors.green.main,
			dark: designTokens.colors.green.dark,
			contrastText: "#fff",
		},
		tertiary: {
			main: designTokens.colors.lightBlue.main,
		},
		background: {
			paper: designTokens.colors.lavender.dark,
			default: designTokens.colors.lavender.main,
		},
		onBackground: designTokens.colors.white,
		divider: designTokens.colors.lavender.dark,
		text: {
			primary: designTokens.colors.purple.main,
			secondary: designTokens.colors.black,
		},
	},
	shadows: createShadows,
	shape: {
		borderRadius: 24,
	},
	typography: {
		h1: designTokens.typography.h1,
		h2: designTokens.typography.h2,
		h3: designTokens.typography.h3,
		h4: designTokens.typography.h4,
		h5: designTokens.typography.h5,
		h6: designTokens.typography.h6,
		body1: designTokens.typography.copyLargeHero,
		body2: designTokens.typography.copyLarge,
		subtitle1: designTokens.typography.copySmallHero,
		subtitle2: designTokens.typography.copySmall,
		button: designTokens.button.common,
	},
});
