export const designTokenFonts = {
	headlines: {
		fontFamily: "GT-Alpina",
		fontSizeFactor: 1,
		textTransform: "none" as const,
	},
	body: {
		fontFamily: "mabry",
		fontSizeFactor: 1,
		textTransform: "none" as const,
	},
};

export const designTokens = {
	button: {
		common: {
			fontFamily: designTokenFonts.body.fontFamily,
			fontWeight: 700,
			fontSize: 16,
			"@media (min-width: 440px)": {
				fontSize: 18,
			},
			textTransform: "none" as const,
		},
		sizes: {
			small: { height: 40 },
			medium: { height: 48 },
			large: { height: 56 },
		},
	},
	typography: {
		h1: {
			fontFamily: designTokenFonts.headlines.fontFamily,
			fontSize: 360,
			fontWeight: 700,
			lineHeight: 1.0,
		},
		h2: {
			fontFamily: designTokenFonts.headlines.fontFamily,
			fontSize: 160,
			fontWeight: 700,
			lineHeight: 1.0,
		},
		h3: {
			fontFamily: designTokenFonts.headlines.fontFamily,
			fontWeight: 700,
			lineHeight: 1.0,
			fontSize: 96 * 0.8,
			"@media (min-width: 440px)": {
				fontSize: 96,
			},
		},
		h4: {
			fontFamily: designTokenFonts.headlines.fontFamily,
			fontWeight: 700,
			lineHeight: 1.0,
			fontSize: 72 * 0.8,
			"@media (min-width: 440px)": {
				fontSize: 72,
			},
		},
		h5: {
			fontFamily: designTokenFonts.headlines.fontFamily,
			fontWeight: 700,
			lineHeight: 1.0,
			fontSize: 64 * 0.8,
			"@media (min-width: 440px)": {
				fontSize: 64,
			},
		},
		h6: {
			fontFamily: designTokenFonts.headlines.fontFamily,
			fontWeight: 700,
			lineHeight: 1.0,
			fontSize: 48 * 0.8,
			"@media (min-width: 440px)": {
				fontSize: 48,
			},
		},
		copyLargeHero: {
			fontFamily: designTokenFonts.body.fontFamily,
			fontSize: 24,
			"@media (min-width: 440px)": {
				fontSize: 24 * 0.9,
			},
			lineHeight: 1.25,
			fontWeight: 700,
		},
		copyLarge: {
			fontFamily: designTokenFonts.body.fontFamily,
			fontSize: 24 * 0.9,
			"@media (min-width: 440px)": {
				fontSize: 24,
			},
			lineHeight: 1.25,
			fontWeight: 400,
		},
		copySmallHero: {
			fontFamily: designTokenFonts.body.fontFamily,
			fontSize: 18 * 0.9,
			"@media (min-width: 440px)": {
				fontSize: 18,
			},
			lineHeight: "26px",
			fontWeight: 700,
		},
		copySmall: {
			fontFamily: designTokenFonts.body.fontFamily,
			fontSize: 18 * 0.9,
			"@media (min-width: 440px)": {
				fontSize: 18,
			},
			lineHeight: "26px",
			fontWeight: 400,
		},
	},
	colors: {
		lightBlue: {
			main: "rgb(214, 239, 238)",
			dark: "rgb(200, 229, 228)",
		},
		lavender: {
			main: "rgb(210, 214, 239)",
			dark: "rgb(194, 198, 229)",
		},
		purple: {
			main: "rgb(41, 19, 129)",
		},
		green: {
			main: "rgb(0, 147, 103)",
			mid: "rgb(7, 81, 59)",
			dark: "rgb(13, 57, 44)",
		},
		grey: {
			mid: "#C2C6E5",
		},
		white: "#fff",
		black: "#000",
	},
};
