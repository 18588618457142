import { ReactNode } from "react";

import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from "@mui/material/IconButton";

import { Theme, styled } from "@/system/theme";

const StyledIconButton = styled(MuiIconButton)(
	({
		theme,
		size = "medium",
		disabled,
		color,
	}: MuiIconButtonProps & { theme: Theme } & {
		color?: "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
		background?: "primary" | "secondary" | "error" | "info" | "success" | "warning";
	}) => {
		let backgroundColor = "transparent";
		let foregroundColor = "transparent";

		switch (color) {
			case "secondary":
				backgroundColor = theme.palette.secondary.main;
				foregroundColor = theme.palette.secondary.contrastText;
				break;
		}

		return {
			opacity: disabled ? 0.5 : 1,
			background: backgroundColor,
			fill: foregroundColor,
			height: { small: 32, medium: 40, large: 48 }[size],
			width: { small: 32, medium: 40, large: 48 }[size],
			padding: theme.spacing({ small: 0.25, medium: 0.25, large: 0.25 }[size]),
		};
	},
);

export const IconButton = (props: {
	children: ReactNode;
	onBackground?: boolean;
	background?: "primary" | "secondary" | "error" | "info" | "success" | "warning";
	disabled?: boolean;
	color?: "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
	size?: "small" | "medium" | "large";
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	onMouseOver?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
	return (
		<StyledIconButton
			size={props.size}
			color={props.color}
			background={props.background}
			onClick={props.onClick}
			disabled={props.disabled || !props.onClick}
		>
			{props.children}
		</StyledIconButton>
	);
};
