import { ChevronLeft } from "./ChevronLeft";
import { Edit } from "./Edit";
import { Info } from "./Info";
import { Menu } from "./Menu";
import { Tune } from "./Tune";
import { LocationOn } from "./LocationOn";
import { ReactComponent as Send } from "@/assets/send.svg";

export const Icons = {
	ChevronLeft,
	Edit,
	Info,
	LocationOn,
	Menu,
	Send,
	Tune,
};
