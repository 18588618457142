import { ApolloProvider, useReactiveVar } from "@apollo/client";

import { StyledEngineProvider } from "@mui/material/styles";

import client from "./apollo/client";
import Root from "./pages/routes";
import { finalTheme } from "./system/theme";
import { ThemeProvider } from "@/system/theme";

import GlobalStyle from "./GlobalStyles";
import "./AppConfig";
import "./SentryConfig";
import { CustomContentProps, SnackbarProvider, useSnackbar } from "notistack";
import { forwardRef, useEffect } from "react";
import Alert from "@mui/material/Alert";
import { errorVar, RateLimitError } from "./apollo/links/error.link";
import { useTranslation } from "react-i18next";
import { colorThemeRapidResponse } from "./system/color-theme.rapid-response";

const Custom = forwardRef<HTMLDivElement, CustomContentProps>((props, forwardedRef) => {
	if (props.variant === "default") {
		return (
			<div ref={forwardedRef}>
				<Alert severity="info">{props.message}</Alert>
			</div>
		);
	}

	return (
		<div ref={forwardedRef}>
			<Alert severity={props.variant}>{props.message}</Alert>
		</div>
	);
});

function App() {
	const persistedTheme = localStorage.getItem("theme");
	const theme = persistedTheme ? JSON.parse(persistedTheme) : null;

	return (
		<ApolloProvider client={client}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={persistedTheme ? finalTheme(theme) : colorThemeRapidResponse}>
					<ThemeProvider theme={persistedTheme ? finalTheme(theme) : finalTheme()}>
						<GlobalStyle />

						<SnackbarProvider
							Components={{
								info: Custom,
								success: Custom,
								error: Custom,
							}}
						>
							<Root />

							<ErrorComponent />
						</SnackbarProvider>
					</ThemeProvider>
				</ThemeProvider>
			</StyledEngineProvider>
		</ApolloProvider>
	);
}

const ErrorComponent = () => {
	const { enqueueSnackbar } = useSnackbar();
	const error = useReactiveVar(errorVar);
	const { t } = useTranslation();

	useEffect(() => {
		if (error instanceof RateLimitError) {
			const durationInSeconds = error.duration;

			if (!durationInSeconds) {
				enqueueSnackbar(t("error.rateLimitExceeded.messageFallback"), {
					variant: "error",
					anchorOrigin: { horizontal: "center", vertical: "bottom" },
				});
			} else {
				const seconds = durationInSeconds % 60;
				const minutes = Math.floor(durationInSeconds / 60) % 60;
				const hours = Math.floor(durationInSeconds / 60 / 60) % 24;

				let messageKey: `error.rateLimitExceeded.${"message" | "messageFallback"}` =
					"error.rateLimitExceeded.message";

				let duration = null;

				if (hours > 0) {
					duration = t("error.rateLimitExceeded.hour", { count: hours });
				} else if (minutes > 0) {
					duration = t("error.rateLimitExceeded.minute", { count: minutes });
				} else if (seconds > 0) {
					duration = t("error.rateLimitExceeded.second", { count: seconds });
				} else {
					messageKey = "error.rateLimitExceeded.messageFallback";
				}

				enqueueSnackbar(t(messageKey, { duration }), {
					variant: "error",
					anchorOrigin: { horizontal: "center", vertical: "bottom" },
				});
			}
		} else if (error?.message) {
			switch (error.code) {
				default:
					enqueueSnackbar(error.message, {
						variant: "error",
						anchorOrigin: { horizontal: "center", vertical: "bottom" },
					});
					break;
			}

			errorVar(null);
		}
	}, [error]);

	return null;
};

export default App;
