import { useRef, FormEvent, FormEventHandler, RefObject } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ErrorBoundary } from "@sentry/react";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import { IconButton } from "@/system/atoms/IconButtons/IconButtons";
import { Icons } from "@/system/atoms/Icons";
import { Caption, CopySmall, CopySmallHero, H6 } from "@/system/atoms/Typography";
import { PrimaryButton } from "@/system/atoms/Buttons";
import { lighten, styled, Theme, useTheme } from "@/system/theme";
import { useSendMailMutation } from "@/generated/graphql";

import { VERSION } from "@/config";

import useStyles from "../styles";

export const Drawer = (props: {
	open: boolean;
	closeDrawer: () => void;
	openDrawer: () => void;
}) => {
	const { t } = useTranslation();
	const classes = useStyles({ scale: 1 });

	const [sendMail] = useSendMailMutation();
	const formRef = useRef<HTMLFormElement>(null);
	const theme = useTheme();

	const onSubmit = async (data: { subject: string; text: string }) => {
		try {
			await sendMail({
				variables: {
					subject: data.subject ?? "Kein Betreff",
					text: data.text,
				},
			});
		} catch (error) {
			console.error("Send", { error });
		}
	};

	const hasCustomFont = theme.typography.h6.fontFamily !== "GT-Alpina";

	return (
		<SwipeableDrawer
			anchor="left"
			open={props.open}
			onClose={props.closeDrawer}
			onOpen={props.openDrawer}
		>
			<Box className={classes.drawer} component="section">
				<Box display="flex" justifyContent="space-between" p={2} component="header">
					<IconButton size="small" onClick={props.closeDrawer} aria-label="Close side menu">
						<Icons.ChevronLeft fontSize="medium" />
					</IconButton>
				</Box>

				<Box px={3} flex={1} component="main">
					<Box mb={4} component="article">
						<header>
							<CopySmallHero color={theme.palette.primary.contrastText}>
								{t("drawer.aboutUs")}
							</CopySmallHero>

							<Box my={2} />

							<H6 as="h2" color={theme.palette.primary.contrastText}>
								{hasCustomFont ? (
									<Trans t={t} i18nKey="drawer.headline">
										For a <span>safer</span> togetherness
									</Trans>
								) : (
									<Trans t={t} i18nKey="drawer.headline">
										For a <i>safer</i> togetherness
									</Trans>
								)}
							</H6>
						</header>

						<Box my={4} />

						<main>
							<CopySmall color={theme.palette.primary.contrastText}>
								{t("drawer.textLine1")}
							</CopySmall>

							<Box my={2} />

							<CopySmall color={theme.palette.primary.contrastText}>
								{t("drawer.textLine2")}
							</CopySmall>
						</main>

						<Box my={2} />

						<footer>
							<CopySmall color={theme.palette.primary.contrastText}>
								<Trans t={t} i18nKey="drawer.textLine3">
									Many thanks & <b>stay healthy!</b>
								</Trans>
							</CopySmall>

							<Box my={2} />

							<CopySmallHero color={theme.palette.primary.contrastText}>
								{t("drawer.textLine4")}
							</CopySmallHero>
						</footer>
					</Box>

					<Box mt={8} mb={2} component="article">
						<form onSubmit={submit(formRef, onSubmit)} ref={formRef}>
							<H6 as="h2" color={theme.palette.primary.contrastText}>
								{hasCustomFont ? (
									<Trans t={t} i18nKey="drawer.sendHeadline">
										We are looking forward to <span>feedback</span>
									</Trans>
								) : (
									<Trans t={t} i18nKey="drawer.sendHeadline">
										We are looking forward to <i>feedback</i>
									</Trans>
								)}
							</H6>

							<Box my={5} />

							<WhiteBorderTextField
								variant="standard"
								label={t("drawer.subject").toString()}
								name="subject"
								fullWidth
							/>

							<Box my={3} />

							<WhiteBorderTextField
								variant="standard"
								label={t("drawer.message").toString()}
								name="text"
								fullWidth
								multiline
								rows={5}
							/>

							<Box my={5} />

							<PrimaryButton type="submit" fullWidth>
								<b>{t("drawer.send").toString()}</b>
							</PrimaryButton>
						</form>
					</Box>

					<Box my={4} display="flex" flexDirection="column" component="nav">
						<Link color={theme.palette.primary.contrastText} href="https://saferspaces.io">
							<Box py={1}>
								<b>www.saferspaces.io</b>
							</Box>
						</Link>

						<Link
							color={theme.palette.primary.contrastText}
							href="https://saferspaces.io/imprint"
							target="blank"
						>
							<Box py={1}>
								<b>Impressum</b>
							</Box>
						</Link>

						<Link
							color={theme.palette.primary.contrastText}
							href="https://saferspaces.io/data-mobile"
							target="blank"
						>
							<Box py={1}>
								<b>Datenschutz</b>
							</Box>
						</Link>
					</Box>

					<Box my={2} display="flex" component="footer">
						<ErrorBoundary>
							<VersionInfo />
						</ErrorBoundary>
					</Box>
				</Box>
			</Box>
		</SwipeableDrawer>
	);
};

const WhiteBorderTextField = styled(TextField)(({ theme }: { theme: Theme }) => ({
	"& label, & label.Mui-focused": {
		color: theme.palette.primary.contrastText,
	},
	"& .Mui-focused": {
		"&::after": {
			borderBottom: `solid ${theme.palette.primary.contrastText} 2px !important`,
		},
	},
	"&.MuiFormControl-root": {
		background: lighten(theme.palette.primary.main, 0.05),
	},
	"& .MuiInput-root": {
		color: theme.palette.primary.contrastText,

		"&::before": {
			borderBottom: `solid ${theme.palette.primary.contrastText} 1px`,
		},

		"&.Mui-focused fieldset": {
			borderColor: theme.palette.primary.contrastText,
		},
	},
}));

const submit = (
	formRef: RefObject<HTMLFormElement>,
	onSubmit: (args: { subject: string; text: string }) => Promise<void>,
): FormEventHandler<HTMLFormElement> => {
	return async (
		event: FormEvent<HTMLFormElement> & {
			target: { subject: { value: string }; text: { value: string } };
		},
	) => {
		console.log(event.target);
		event.preventDefault();

		try {
			await onSubmit({ subject: event.target.subject.value, text: event.target.text.value });

			formRef.current?.reset();
		} catch (error) {
			console.error(error);
		}
	};
};

const VersionInfo = () => {
	return (
		<Box py={1}>
			<Caption color="white">Version:</Caption>
			<Caption color="white">{VERSION}</Caption>
		</Box>
	);
};

export default Drawer;
